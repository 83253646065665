import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"

import brandingFeaturedImage from "../../../static/branding/nubinix/nubinix-featured-image.png"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/branding/nubinix.scss"
import NubinixMain from "../../components/branding/nubinix/nubinixMain"
import NubinixFullwidth from "../../components/branding/nubinix/nubinixFullWidth"
import NubinixMoreBranding from "../../components/branding/nubinix/nubinixMoreBranding"

export const Head = () => (
    <>
        <SeoHead 
            ogImage={brandingFeaturedImage}
            title="Nubinix: Cloud Branding Innovation | WowMakers"
            description="Explore how we elevated Nubinix's brand in the cloud computing space. Cutting-edge design for cutting-edge tech."
        />
    </>
)


const MayabaeBranding = () => {
    return (
        <Layout>
            <Seo
                bid="branding-nubinix"
                bclass="case-studies-page branding" 
            >
            </Seo>
            <ScrollIndicationHeightZero />
            <NubinixMain />
            <NubinixFullwidth />
            <NubinixMoreBranding />
        </Layout>
    )
}

export default MayabaeBranding
